/**
 * 数值配置
 * 配置各种策划提供的数值
 *
 */
export default {
  depositDefaultOptions: [
    { get: 204000, more: 2, hot: 0, price: 200000 },
    { get: 307500, more: 2.5, hot: 0, price: 300000 },
    { price: 500000, get: 500000 },
    { get: 828000, more: 3.5, hot: 0, price: 800000 },
    { get: 1040000, more: 4, hot: 0, price: 1000000 },
    { get: 2080000, more: 4, hot: 0, price: 2000000 },
    { get: 3150000, more: 4.5, hot: 0, price: 3000000 },
    { get: 5250000, more: 5, hot: 0, price: 5000000 },
    { get: 10500000, more: 5, hot: 0, price: 10000000 }
  ],
  firstRechargeAmount: 500000,
  //限时活动
  limitDiscounts: 3000000,
  firstRechargeBonusRate: 0.2,
  firstRechargedWithdrawNeedBets: 8400000,
  depositActivityAmount: 300000,
  depositActivityMore: 20,
  defaultDepositGot: 2040,
  defaultDepositPrice: 2000,
  minDepositAmount: 200000,
  maxDepositAmount: 280000000,
  minWithdrawAmount: 300000,
  maxWithdrawAmount: 56000000,
  maxWithdrawDailyAmount: 840000000,
  inviteWheel: {
    targetAmount: 3000000,
  },
  luckyDraw: {
    marqueeAmoundList: [12, 120, 30, 300, 1200, 3000, 30, 12, 120, 120, 12, 12],
  },
  addHomeScreenRewardFrom: 140,
  addHomeScreenRewardTo: 28000,
  gasBurnRatio: 0,
  betGasRatio: 1,
  depositGasRatio: 1,
  useWithdrawLevel: 1,
};
